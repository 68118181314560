import axios from "axios";
import { apiUrl, defaultHeaders, buildPath, appendHeader } from "./helpers";

/**
 * @name httpServices
 * @description Communitacion service that allow the app to connect to the api using http request.
 */
function httpServices() {
  /**
   * Create an Axios instance.
   */
  const httpInstance = axios.create({
    baseURL: apiUrl,
    headers: defaultHeaders,
    timeout: 0,
    withCredentials: true,
  });

  /**
   * @name get
   * @description make a GET request
   * @param {Object} options - options to make the GET resquest.
   * @param {string} options.path - the path for the GET request.
   * @param {Object} options.params - Object with querystring values.
   */
  function get(options) {
    const path = buildPath(options);
    return httpInstance
      .get(path)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  /**
   * @name post
   * @description make a POST request
   * @param {Object} options - options to make the POST resquest.
   * @param {string} options.path - the path for the POST request.
   * @param {Object} options.params - Object with querystring values.
   * @param {Object} options.data - Object with body values.
   */
  function post(options) {
    const path = buildPath(options);
    httpInstance.defaults.headers = appendHeader(options);

    return httpInstance
      .post(path, options.data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  /**
   * @name patch
   * @description make a PATCH request
   * @param {Object} options - options to make the PATCH resquest.
   * @param {string} options.path - the path for the PATCH request.
   * @param {Object} options.params - Object with querystring values.
   * @param {Object} options.data - Object with body values.
   * @param {boolean} options.formData - body data have to be sent as ultipart/form-data default is false.
   */
  function patch(options) {
    const path = buildPath(options);
    httpInstance.defaults.headers = appendHeader(options);

    return httpInstance
      .patch(path, options.data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  /**
   * @name put
   * @description make a PUT request
   * @param {Object} options - options to make the PUT resquest.
   * @param {string} options.path - the path for the PUT request.
   * @param {Object} options.params - Object with querystring values.
   * @param {Object} options.data - Object with body values.
   * @param {boolean} options.formData - body data have to be sent as ultipart/form-data default is false.
   */
  function put(options) {
    const path = buildPath(options);
    httpInstance.defaults.headers = appendHeader(options);

    return httpInstance
      .put(path, options.data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  return {
    get,
    post,
    patch,
    put,
  };
}

export default httpServices();
