import styled from "styled-components";
import { breakPoints } from "../../../../globals/breakpoints";
import { Colors } from "../../../../globals/colors";

const HistoryContainer = styled.section`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.tertiary1000};
  width: 100%;
`;

const HistoryPattern = styled.div`
  background-image: url("/images/history_pattern.png");
  background-size: contain;
  height: 80px;
  width: 100%;

  ${({ isReversed }) =>
    isReversed &&
    `
    transform: rotate(180deg);
  `}

  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    height: 70px;
  }
`;

const TitleBox = styled.div`
  width: 100%;

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const HistoryWrapper = styled.div`
  width: 850px;
  height: fit-content;
  background-color: ${Colors.primary600};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  margin-top: 48px;
  margin-bottom: 48px;

  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    width: 100%;
    max-width: 745px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    margin-top: 32px;
    margin-bottom: 0;
  }
`;

const HistoryBox = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;
  min-height: ${({ isXs }) => (isXs ? "93px" : "134px")};
  display: flex;
  margin-top: ${({ isXs }) => (isXs ? "10px" : "30px")};
  margin-bottom: ${({ isXs }) => (isXs ? "10px" : "30px")};
`;

export { HistoryContainer, HistoryPattern, TitleBox, HistoryWrapper, HistoryBox };
