/* eslint-disable no-console */
import { useEffect, useState } from "react";
import keccak256 from "keccak256";
import { ToastContainer } from "react-toastify";
import { Button } from "../../../../components/atoms";
import {
  AdminButtonsWrapper,
  AdminForm,
  AdminInput,
  ButtonsWithInputsBox,
  ButtonsWithoutInputsBox,
} from "./AdminButtons.styled";
import Fox1337Contract from "./../../../../contracts/Fox1337.json";
import { generateToken } from "../../../../utils/generateToken";
import { merkleTree } from "../../../../utils/merkleTree";
import { useReactContext } from "../../../../context/Context";
import {
  getNotRepeatedTokens,
  getNumberOfTokensToPay,
  setNotRepeatedTokens,
} from "../../../../utils/mintHelpers";
import { useToast } from "../../../../utils/hooks/useToast";

function Buttons({ web3 }) {
  const [fox1337Instance, setFox1337Instance] = useState(null);
  const { account } = useReactContext();

  useEffect(async () => {
    const networkId = await web3.eth.net.getId();
    const deployedNetwork = Fox1337Contract.networks[networkId];
    const instance = new web3.eth.Contract(
      Fox1337Contract.abi,
      deployedNetwork && deployedNetwork.address
    );
    setFox1337Instance(instance);
  }, []);

  //baseURI()
  const handleClickBaseUri = () => {
    fox1337Instance.methods
      .contractURI()
      .call()
      .then((baseURI) => console.log(baseURI));
  };

  //pause()
  const handleClickPause = async () => {
    const pauseContract = () => fox1337Instance.methods.pause().send({ from: account });
    useToast(pauseContract, "Successfully paused", "Error while pausing");
  };

  //unpause()
  const handleClickUnpause = () => {
    const unpauseContract = () => fox1337Instance.methods.unpause().send({ from: account });
    useToast(unpauseContract, "Successfully unpaused", "Error while unpausing");
  };

  //reserve1337Fox()
  const handleClickReserve = () => {
    const tokensToReserve = [];
    for (let i = 0; i < 37; i++) {
      tokensToReserve.push(generateToken());
    }

    const reserve = () =>
      fox1337Instance.methods
        .reserve1337Fox(tokensToReserve, tokensToReserve.length)
        .send({ from: account });
    useToast(reserve, "Successfully reserved", "Error while reserving", () =>
      setNotRepeatedTokens(tokensToReserve)
    );
  };

  //withdraw()
  const handleClickWithdraw = () => {
    const withdraw = () => fox1337Instance.methods.withdraw().send({ from: account });
    useToast(withdraw, "Successfully withdrawed", "Error while withdrawing");
  };

  //setBaseURI()
  const handleClickSetBaseURI = (event) => {
    event.preventDefault();

    const { value } = event.target.input;
    const setBaseURI = () => fox1337Instance.methods.setBaseURI(value).send({ from: account });
    useToast(setBaseURI, "Successfully set", "Error while setting");
  };

  //tokenURI()
  const handleClickTokenURI = (event) => {
    event.preventDefault();

    const tokenId = event.target.tokenid.value;
    fox1337Instance.methods
      .tokenURI(web3.utils.toBN(tokenId))
      .call()
      .then((tokenURI) => console.log(tokenURI));
  };

  //mint1337Fox()
  const handleClickMint = async (event) => {
    event.preventDefault();

    const numberOfTokens = event.target.input.value;
    if (numberOfTokens <= 0 || numberOfTokens > 7) {
      return;
    }

    const merkleProof = merkleTree.getHexProof(keccak256(account));
    const tokensBalance = await fox1337Instance.methods.balanceOf(account).call();

    const numberOfTokensToPay = getNumberOfTokensToPay(numberOfTokens, tokensBalance, merkleProof);
    const value = web3.utils.toWei((Number(numberOfTokensToPay) * 0.037).toString());

    const tokensToMint = await getNotRepeatedTokens(numberOfTokens);

    const mint = () =>
      fox1337Instance.methods
        .mint1337Fox(account, tokensToMint, numberOfTokens, merkleProof)
        .send({ from: account, value });
    useToast(mint, "Successfully minted", "Error while minting", () =>
      setNotRepeatedTokens(tokensToMint)
    );
  };

  return (
    <>
      <AdminButtonsWrapper>
        <ButtonsWithoutInputsBox>
          <Button onClick={handleClickBaseUri}>contractURI</Button>
          <Button onClick={handleClickPause}>Pause</Button>
          <Button onClick={handleClickUnpause}>Unpause</Button>
          <Button onClick={handleClickReserve}>Reserve 1337Fox</Button>
          <Button onClick={handleClickWithdraw}>Withdraw</Button>
        </ButtonsWithoutInputsBox>
        <ButtonsWithInputsBox>
          <AdminForm onSubmit={handleClickSetBaseURI}>
            <AdminInput placeholder="Base URI" name="input" />
            <Button type="submit">Set baseURI</Button>
          </AdminForm>
          <AdminForm onSubmit={handleClickTokenURI}>
            <AdminInput placeholder="Token ID" name="tokenid" />
            <Button type="submit">Get tokenURI</Button>
          </AdminForm>
          <AdminForm onSubmit={handleClickMint}>
            <AdminInput
              placeholder="Number of tokens (1-7)"
              type="number"
              min="1"
              max="7"
              name="input"
            />
            <Button type="submit">Mint 1337Fox</Button>
          </AdminForm>
        </ButtonsWithInputsBox>
      </AdminButtonsWrapper>
      <ToastContainer position={"top-center"} autoClose={3500} />
    </>
  );
}

export default Buttons;
