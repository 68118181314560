import { URLS } from "../../../globals/constants";
import { Colors } from "../../../globals/colors";
import useBreakpoints from "../../../utils/useBreakpoints";
import { Button, ButtonSizes, Icon, Text } from "../../atoms";
import { FooterContainer, FooterWrapper, IconBox } from "./Footer.styled";
import { ButtonContainer } from "../../atoms/Button/Button.styled";

function Footer() {
  const { isXs } = useBreakpoints();
  return (
    <FooterWrapper>
      <FooterContainer>
        <Text as="h1" color={Colors.tertiary1000} style={{ marginBottom: "40px" }}>
          Are you a survivor?
        </Text>
        <ButtonContainer>
          <a
            href={URLS.DISCORD_CHANNEL}
            target="_blank"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "60px",
            }}
          >
            <Button size={isXs ? ButtonSizes.FULL_WIDTH : ButtonSizes.FIXED}>Join us!</Button>
          </a>
        </ButtonContainer>

        <IconBox>
          <a href={URLS.TWITTER_PROFILE} target="_blank">
            <Icon src="/images/twitter.svg" alt="Twitter logo" />
          </a>
          <a href={URLS.DISCORD_CHANNEL} target="_blank">
            <Icon src="/images/discord.svg" alt="Discord logo" />
          </a>
          <a href={URLS.INSTAGRAM_PROFILE} target="_blank">
            <Icon src="/images/instagram.svg" alt="Instagram logo" />
          </a>
          <a href={URLS.MEDIUM} target="_blank">
            <Icon src="/images/medium.svg" alt="Medium logo" />
          </a>
          <a href={URLS.OPENSEA_COLLECTION} target="_blank">
            <Icon src="/images/opensea.svg" alt="Opensea logo" />
          </a>
        </IconBox>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;
