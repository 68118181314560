import styled from "styled-components";
import { breakPoints } from "../../globals/breakpoints";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleBox = styled.div`
  width: 100%;

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const TokenContainer = styled.div`
  display: flex;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.md}px) {
    flex-direction: column;
  }
`;

const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.md}px) {
    margin-right: 0px;
    margin-bottom: 75px;
  }
`;

const TokenBox = styled.div`
  display: flex;
  align-items: center;
`;

const TokenPartsWrapper = styled.div`
  height: fit-content;
  max-width: 468px;
  max-height: 383px;
  display: grid;
  column-gap: 21px;
  row-gap: 26px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-width: 325px;
    max-height: 627px;
    margin: auto;
  }
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 36px;
`;

const SocialButton = styled.img`
  background-color: ${({ color }) => color};
  height: 44px;
  width: 142px;
  border-radius: 5px;
  cursor: pointer;
`;

export {
  DashboardContainer,
  TitleBox,
  TokenContainer,
  TokenWrapper,
  TokenBox,
  TokenPartsWrapper,
  SocialButton,
  SocialButtonsWrapper,
};
