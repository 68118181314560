import styled from "styled-components";
import { fadeDirection } from "./FadingImage";

const handleInitialPosition = (direction, width) => {
  let initialPosition = "";
  switch (direction) {
    case fadeDirection.TO_LEFT:
      initialPosition = `right: -${width}px;`;
      break;
    case fadeDirection.TO_RIGHT:
      initialPosition = `left: -${width}px;`;
      break;
  }
  return initialPosition;
};

const handleFinalPosition = (direction, edgeGap = 0) => {
  let finalPosition = "";
  switch (direction) {
    case fadeDirection.TO_LEFT:
      finalPosition = `right: ${0 + edgeGap}px;`;
      break;
    case fadeDirection.TO_RIGHT:
      finalPosition = `left: ${0 + edgeGap}px;`;
      break;
  }
  return finalPosition;
};

const SCFadingImage = styled.img`
  position: absolute;
  transition: opacity 1s ease-out, right 1s ease-out, left 1s ease-out;
  opacity: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  ${({ width, fadeDirection }) => `
    ${handleInitialPosition(fadeDirection, width)}
    width: ${width}px;
  `}
  ${({ isShown, fadeDirection, edgeGap }) =>
    isShown &&
    `
    ${handleFinalPosition(fadeDirection, edgeGap)}
    opacity: 1;
  `}
`;

export { SCFadingImage };
