import React, { useEffect, useState } from "react";
import { useReactContext } from "../../../context/Context";
import { LedButtonWrapper, LedButtonText, Led } from "./LedButton.styled";

function LedButton({
  dataTestId = "led-button",
  disabled = false,
  onClick,
  children,
  ...otherProps
}) {
  const { account } = useReactContext();
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    account ? setIsConnected(true) : setIsConnected(false);
  }, [account]);

  return (
    <LedButtonWrapper onClick={onClick} disabled={disabled} {...otherProps}>
      <LedButtonText>{children}</LedButtonText>
      <Led isConnected={isConnected} />
    </LedButtonWrapper>
  );
}

export default LedButton;
