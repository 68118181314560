import { URLS } from "../../../globals/constants";

export const getLayersByTokenId = (tokenId) => {
  return {
    Headgear: `${URLS.IPFS_LAYERS}/Headgear/${tokenId.slice(0, 2)}.png`,
    "Background Color": `${URLS.IPFS_LAYERS}/BackgroundColor/${tokenId.slice(2, 4)}.png`,
    "Body Color": `${URLS.IPFS_LAYERS}/BodyColor/${tokenId.slice(4, 6)}.png`,
    Face: `${URLS.IPFS_LAYERS}/Face/${tokenId.slice(6, 8)}.png`,
    "Body Parts": `${URLS.IPFS_LAYERS}/Bodyparts/${tokenId.slice(8, 10)}.png`,
    Tailpieces: `${URLS.IPFS_LAYERS}/Tailpieces/${tokenId.slice(10, 12)}.png`,
  };
};
