import styled from "styled-components";
import { Colors } from "../../globals/colors";

const AdminContainer = styled.div`
  background-color: ${Colors.backgrounds1000};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: ${Colors.backgrounds1000};
`;

export { AdminContainer, ButtonsWrapper };
