import { useRef, useEffect } from "react";
import { HamburguerWrapper } from "./Hamburguer.styled";

const Hamburguer = ({ isActive, setIsActive, ...otherProps }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.addEventListener("blur", hideModal);

    return () => {
      ref.current?.removeEventListener("blur", hideModal);
    };
  }, []);

  const hideModal = () => {
    setIsActive(false);
  };

  return (
    <HamburguerWrapper
      ref={ref}
      isActive={isActive}
      onClick={() => setIsActive(!isActive)}
      tabIndex={0}
      {...otherProps}
    >
      <span className="line line-1"></span>
      <span className="line line-2"></span>
      <span className="line line-3"></span>
    </HamburguerWrapper>
  );
};

export default Hamburguer;
