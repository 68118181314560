import { useEffect, useState } from "react";
import { Colors } from "../../globals/colors";
import { ROUTES, URLS } from "../../globals/constants";
import TokensService from "../../services/TokensService";
import EtherscanService from "../../services/EtherscanService";
import { getLayersByTokenId } from "./utils/tokenLayers";
import useBreakpoints from "../../utils/useBreakpoints";
import { AttributeCard, TokenIframe } from "./components";
import { Text } from "../../components/atoms";
import {
  DashboardContainer,
  SocialButton,
  SocialButtonsWrapper,
  TitleBox,
  TokenBox,
  TokenContainer,
  TokenPartsWrapper,
  TokenWrapper,
} from "./Dashboard.styled";
import ArrowButton, { ArrowDirection } from "../../components/atoms/ArrowButton";

function Dashboard({ web3 }) {
  const [tokensId, setTokensId] = useState([]);
  const [currentToken, setCurrentToken] = useState(0);
  const [currentTokenMetadata, setCurrentTokenMetadata] = useState({});

  const { isXs } = useBreakpoints();

  useEffect(async () => {
    if (web3) {
      const accounts = await web3.eth.getAccounts();
      EtherscanService.getTokensByAddress(accounts[0]).then(({ result }) => {
        if (result.includes("Error")) {
          return;
        }
        const tokens = result.map((tokens) => tokens.tokenID);
        setTokensId(tokens);
      });
    }
  }, [web3]);

  useEffect(() => {
    if (tokensId.length > 0) {
      TokensService.getMetadataByTokenId(tokensId[currentToken]).then((metadata) => {
        setCurrentTokenMetadata(metadata);
      });
    }
  }, [currentToken, tokensId]);

  const handleClickNextToken = () => {
    let newToken = currentToken + 1;
    if (newToken >= tokensId.length) {
      newToken = 0;
    }
    setCurrentToken(newToken);
  };

  const handleClickPreviowsToken = () => {
    let newToken = currentToken - 1;
    if (newToken < 0) {
      newToken = tokensId.length - 1;
    }
    setCurrentToken(newToken);
  };

  return (
    <DashboardContainer>
      <TitleBox>
        <Text
          as="h1"
          color={Colors.tertiary1000}
          textAlign={isXs ? "left" : "center"}
          style={{ marginTop: isXs ? "45px" : "32px", marginBottom: isXs ? "49px" : "56px" }}
        >
          My Foxes
        </Text>
      </TitleBox>
      {tokensId.length === 0 && (
        <Text as="h3" style={{ padding: "0 25px" }}>
          Looks like you don't have any foxes yet, want to{" "}
          <a href={ROUTES.HOME} style={{ color: Colors.tertiary1000 }}>
            mint one?
          </a>
        </Text>
      )}
      {tokensId.length > 0 && (
        <TokenContainer>
          <TokenWrapper>
            <TokenBox>
              <ArrowButton onClick={handleClickPreviowsToken} direction={ArrowDirection.LEFT} />
              <TokenIframe src={currentTokenMetadata.external_url} />
              <ArrowButton onClick={handleClickNextToken} direction={ArrowDirection.RIGHT} />
            </TokenBox>
            <Text as="h2" style={{ marginTop: "16px" }}>
              {currentTokenMetadata.name}
            </Text>
            <SocialButtonsWrapper>
              <a href={URLS.OPENSEA_TOKEN + tokensId[currentToken]} target="_blank">
                <SocialButton
                  src="/images/opensea-button.svg"
                  color={Colors.openseaBlue}
                  style={{ padding: "8px 13px" }}
                />
              </a>
              <a
                href={URLS.TWITTER_SHARE({ imageURL: currentTokenMetadata.image })}
                target="_blank"
              >
                <SocialButton
                  src="/images/twitter-share-button.svg"
                  color={Colors.twitterBlue}
                  style={{ padding: "0" }}
                />
              </a>
            </SocialButtonsWrapper>
          </TokenWrapper>
          <TokenPartsWrapper>
            {currentTokenMetadata.attributes?.map((attribute, index) => (
              <AttributeCard
                key={index}
                attribute={attribute}
                layers={getLayersByTokenId(tokensId[currentToken])}
              />
            ))}
          </TokenPartsWrapper>
        </TokenContainer>
      )}
    </DashboardContainer>
  );
}

export default Dashboard;
