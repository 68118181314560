import React from "react";
import { Colors } from "../../../globals/colors";
import { ArrowButtonWrapper } from "./ArrowButton.styled";
import ArrowButtonSVG from "./ArrowButtonSVG";

export const ArrowDirection = {
  TOP: "TOP",
  RIGHT: "RIGHT",
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
};

function ArrowButton({ onClick, direction }) {
  return (
    <ArrowButtonWrapper onClick={onClick} direction={direction}>
      <ArrowButtonSVG color={Colors.primary800} size={56} />
    </ArrowButtonWrapper>
  );
}

export default ArrowButton;
