import styled from "styled-components";

const CardWrapper = styled.div`
  position: relative;
  background-image: url(${({ image }) => image});
  background-size: contain;
  width: 263px;
  height: 390px;
  transform-style: preserve-3d;
  transition: transform 300ms ease-out;
  /* The transition is causing perspective issues in Safari. Must be disabled. */
  ${() => navigator.vendor.includes("Apple") && `transition: none;`}
`;

const CardIconsWrapper = styled.div`
  position: absolute;
  right: 17px;
  top: 13px;
  display: grid;
  grid-template-columns: ${({ links }) => `repeat(${links.length}, 1fr)`};
  gap: 0px 4.5px;
`;

const CardIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const CardImage = styled.img`
  position: absolute;
  top: 65px;
  left: 26px;
  width: 212px;
  height: 212px;
  transform: translateZ(50px);
`;

export { CardWrapper, CardIconsWrapper, CardIcon, CardImage };
