import styled, { keyframes } from "styled-components";
import { breakPoints } from "../../../globals/breakpoints";
import { Colors } from "../../../globals/colors";

const glow = (isConnected) => keyframes`
  0% {
    background-color: ${isConnected ? Colors.secondary900 : Colors.danger900};
  }
  50% {
    background-color: ${isConnected ? Colors.secondary1000 : Colors.danger1000};
  }
  100% {
    background-color: ${isConnected ? Colors.secondary900 : Colors.danger900};
  }
`;

const LedButtonWrapper = styled.button`
  width: 220px;
  height: 56px;
  border-radius: 26px;
  background-color: ${Colors.primary800};
  border: 1px solid ${Colors.primary800};
  padding: 0;
  cursor: pointer;
  transition: background-color 200ms, border 200ms;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  &:hover,
  &:focus {
    background-color: ${Colors.primary900};
    border: 1px solid ${Colors.primary900};
    outline: none;
  }
  &:active {
    background-color: ${Colors.primary1000};
    border: 1px solid ${Colors.primary1000};
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: ${Colors.gray700};
    border-color: ${Colors.gray700};
    &:hover,
    &:focus,
    &:active {
      background-color: ${Colors.gray700};
    border-color: ${Colors.gray700};
    }
    & > span {
      color: ${Colors.gray800};
    }
    & > div {
      background-color: ${Colors.gray800};
      animation: none;
    }
  `}

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    width: 130px;
    height: 36px;
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    width: 165px;
    height: 48px;
  }
`;

const LedButtonText = styled.span`
  color: ${Colors.gray200};
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin-left: 2px;
  text-align: center;
  width: 75%;
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    font-size: 22px;
    line-height: 26px;
  }
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Led = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${Colors.gray200};
  background-color: ${({ isConnected }) => (isConnected ? Colors.secondary900 : Colors.danger900)};
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ isConnected }) => glow(isConnected)} 1.5s infinite ease-in-out;

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    width: 24px;
    height: 24px;
    top: 5px;
    right: 6px;
    border-width: 1px;
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    width: 32px;
    height: 32px;
    top: 7px;
    right: 8px;
    border-width: 1px;
  }
`;

export { LedButtonWrapper, LedButtonText, Led };
