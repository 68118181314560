import styled from "styled-components";
import { breakPoints } from "../../../globals/breakpoints";
import { textPosition } from "./FadingText";

const SCFadingText = styled.div`
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0;
  transition: opacity 1500ms ease-out;
  ${({ position }) => `
    padding-left: ${position === textPosition.LEFT ? "40px" : "0px"};
    padding-right: ${position === textPosition.RIGHT ? "40px" : "0px"};
    
    @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
      padding-left: ${position === textPosition.LEFT ? "32px" : "0px"};
      padding-right: ${position === textPosition.RIGHT ? "32px" : "0px"};
    }
    
    @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
      padding-left: ${position === textPosition.LEFT ? "25px" : "0px"};
      padding-right: ${position === textPosition.RIGHT ? "25px" : "0px"};
    }
  `}
  ${({ isShown }) =>
    isShown &&
    `
    opacity: 1;
  `}
  p {
    font-size: 16px;
  }
`;

export { SCFadingText };
