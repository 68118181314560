import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import getWeb3 from "../../utils/web3";
import { TopNavBar } from "../../components/molecules";
import { useReactContext } from "../../context/Context";
import { CHAIN_IDS } from "../../globals/constants";
import Fox1337Contract from "./../../contracts/Fox1337.json";
import { ChildrenContainer, MainLayoutContainer } from "./MainLayout.styled";

function MainLayout({ children }) {
  const { setAccount } = useReactContext();
  const [web3, setWeb3] = useState(null);
  const [fox1337Instance, setFox1337Instance] = useState(null);
  const historyRef = useRef(null);
  const teamRef = useRef(null);

  const handleAccountsChanged = async () => {
    const account = await web3.eth.getAccounts();
    setAccount(account[0]);
  };

  useEffect(async () => {
    // Wallet extension not installed
    if (!window.ethereum) {
      toast.info("Looks like you don't have a wallet browser extension.");
      return;
    }
    const { chainId } = window.ethereum;
    // Not connected to the mainnet
    if (chainId && chainId !== CHAIN_IDS.MAINNET) {
      toast.info("You must be connected to the ethereum main network.");
      return;
    }
    const newWeb3 = await getWeb3();
    setWeb3(newWeb3);
  }, []);

  useEffect(async () => {
    if (web3) {
      const account = await web3.eth.getAccounts();
      setAccount(account[0]);
      const networkId = await web3.eth.net.getId();
      const deployedNetwork = Fox1337Contract.networks[networkId];
      const instance = new web3.eth.Contract(
        Fox1337Contract.abi,
        deployedNetwork && deployedNetwork.address
      );
      setFox1337Instance(instance);

      window.ethereum.on("accountsChanged", handleAccountsChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      };
    }
  }, [web3]);

  const connectWallet = async () => {
    if (web3) {
      if (!window.ethereum.selectedAddress) {
        await web3.eth.requestAccounts().catch(() => {});
      }
    }
  };

  const ChildrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { fox1337Instance, web3, historyRef, teamRef });
    }
    return child;
  });

  return (
    <MainLayoutContainer>
      <TopNavBar
        connectWallet={connectWallet}
        isWeb3Connected={web3 !== null}
        refsToScroll={{ historyRef, teamRef }}
      />
      <ChildrenContainer>{ChildrenWithProps}</ChildrenContainer>
    </MainLayoutContainer>
  );
}

export default MainLayout;
