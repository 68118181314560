import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../globals/constants";
import Admin from "./admin";
import Dashboard from "./dashboard";
import Home from "./home";
import { MainLayout } from "./../layouts";

const App = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route
        path={ROUTES.DASHBOARD}
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
      <Route exact path={ROUTES.ADMIN} element={<Admin />} />
    </Routes>
  );
};

export default App;
