import { CONTRACT_ADDRESS } from "../globals/constants";

const buildEtherscanUrl = (userAddress) => {
  return `https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${CONTRACT_ADDRESS}&address=${userAddress}&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;
};

const EtherscanService = () => {
  const getTokensByAddress = (address) => {
    const path = buildEtherscanUrl(address);
    return fetch(path)
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  };
  return {
    getTokensByAddress,
  };
};

export default EtherscanService();
