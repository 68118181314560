import styled from "styled-components";
import { Colors } from "../../../globals/colors";
import { breakPoints } from "./../../../globals/breakpoints";

const TopNavBarWrapper = styled.nav`
  width: 100%;
  height: 85px;
  background-color: ${Colors.backgrounds1000};
  padding: 13px 50px;

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    height: 64px;
    padding: 13px 22px;
  }
`;

const TopNavBarContainer = styled.div`
  max-width: 2160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

const LeftBox = styled.div`
  width: fit-content;
  & img {
    height: 60px;

    @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
      height: 34px;
    }
  }
`;
const RightBox = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    gap: 18px;
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  gap: 24px;

  & img,
  a {
    height: 45px;
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    & img,
    a {
      height: 35px;
    }
  }
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    gap: 18px;
    & img,
    a {
      height: 30px;
    }
  }
`;

const TopNavBarModal = styled.div`
  background-color: ${Colors.primary200};
  width: 220px;
  height: fit-content;
  border-radius: 16px;
  position: absolute;
  top: 85px;
  right: 42px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 30px;
  gap: 15px 0px;
  transition: all 400ms ease-out;
  opacity: 0;
  visibility: hidden;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    border-bottom: 20px solid ${Colors.primary200};
    right: 5px;
    top: -35px;
  }

  ${({ isActive }) =>
    isActive &&
    `
      opacity: 0.95;
      visibility: visible;
  `}

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    top: 64px;
    right: 12px;
    width: 175px;
    padding: 15px 25px;
    gap: 10px 0px;
    &:before {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparent;
      border-bottom: 15px solid ${Colors.primary200};
      right: 5px;
      top: -25px;
    }
  }
`;

export { TopNavBarWrapper, TopNavBarContainer, LeftBox, RightBox, LogosWrapper, TopNavBarModal };
