import { Colors } from "../../../globals/colors";
import { SCText } from "./Text.styled";

function Text({ color = Colors.gray900, as = "p", textAlign = 'center', uppercase = false, fontStyle = 'normal', children, ...otherProps }) {
  return (
    <SCText as={as} color={color} textAlign={textAlign} uppercase={uppercase} fontStyle={fontStyle} {...otherProps}>
      {children}
    </SCText>
  );
}

export default Text;
