import Text from "../Text";
import { SelectorSymbol, SelectorWrapper } from "./Selector.styled";

function Selector({ numberOfTokens, setNumberOfTokens }) {
  const handleClickSubstract = () => {
    if (numberOfTokens > 1) {
      setNumberOfTokens(--numberOfTokens);
    }
  };

  const handleClickAdd = () => {
    if (numberOfTokens < 7) {
      setNumberOfTokens(++numberOfTokens);
    }
  };
  return (
    <SelectorWrapper>
      <SelectorSymbol onClick={handleClickSubstract} src="/images/minus-circle.svg" />
      <Text as="h2">{numberOfTokens}</Text>
      <SelectorSymbol onClick={handleClickAdd} src="/images/plus-circle.svg" />
    </SelectorWrapper>
  );
}

export default Selector;
