/* eslint-disable no-console */
import TokensService from "../services/TokensService";
import { generateToken } from "./generateToken";

const getNumberOfTokensToPay = (numberOfTokens, tokensBalance, merkleProof) => {
  let number = numberOfTokens;
  if (tokensBalance == 0 && merkleProof.length !== 0) {
    number -= 1;
  }
  return number;
};

const isTokenRepeated = (tokensToMint, tokensFromDB) => {
  let isRepeated = false;

  tokensFromDB.forEach(({ tokenId }) => {
    if (tokensToMint.includes(tokenId)) {
      isRepeated = true;
      return;
    }
  });
  return isRepeated;
};

const getNotRepeatedTokens = (numberOfTokens) => {
  return TokensService.getTokensList().then((tokensFromDB) => {
    let tokensToMint = [];
    let isRepeated = true;
    while (isRepeated) {
      for (let i = 0; i < Number(numberOfTokens); i++) {
        tokensToMint.push(generateToken());
      }
      isRepeated = isTokenRepeated(tokensToMint, tokensFromDB);
    }
    return tokensToMint;
  });
};

const setNotRepeatedTokens = async (tokens) => {
  await TokensService.setTokens({ tokens })
    .then((tokensMinted) => console.log("Tokens minted: ", tokensMinted))
    .catch((error) => console.error(error));
};

export { getNumberOfTokensToPay, isTokenRepeated, getNotRepeatedTokens, setNotRepeatedTokens };
