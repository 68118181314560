/* eslint-disable react/display-name */
import React, { useState } from "react";
import { ContextProvider } from "./Context";

export const initContract = {};
export const initAccount = null;

export default ({ children = null }) => {
  const [contract, setContract] = useState(initContract);
  const [account, setAccount] = useState(initAccount);
  return (
    <ContextProvider value={{ contract, setContract, account, setAccount }}>
      {children}
    </ContextProvider>
  );
};
