import useMediaQuery from './useMediaQuery';
import { breakPoints } from '../globals/breakpoints';

export default function useBreakpoints() {
  const breakpoints = {
    isXs: useMediaQuery(`(max-width: ${breakPoints.sm}px)`),
    isSm: useMediaQuery(
      `(min-width: ${breakPoints.sm + 1}px) and (max-width: ${breakPoints.md}px)`
    ),
    isMd: useMediaQuery(
      `(min-width: ${breakPoints.md + 1}px) and (max-width: ${breakPoints.lg}px)`
    ),
    isLg: useMediaQuery(
      `(min-width: ${breakPoints.lg + 1}px) and (max-width: ${breakPoints.xl}px)`
    ),
    isXl: useMediaQuery(`(min-width: ${breakPoints.xl + 1}px)`),
    active: null,
  };
  if (breakpoints.isXs) breakpoints.active = 'xs';
  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';
  if (breakpoints.isXl) breakpoints.active = 'xl';
  return breakpoints;
}
