import { ButtonText, ButtonWrapper } from "./Button.styled";

export const ButtonStatus = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const ButtonVariants = {
  FILLED: "filled",
  OUTLINED: "outlined",
};

export const ButtonSizes = {
  FIXED: "fixed",
  FULL_WIDTH: "full width",
};

const Button = ({
  dataTestId = "button",
  status = ButtonStatus.PRIMARY,
  variant = ButtonVariants.FILLED,
  size = ButtonSizes.FULL_WIDTH,
  disabled = false,
  onClick,
  children,
  ...otherProps
}) => {
  return (
    <ButtonWrapper
      type="button"
      data-testid={dataTestId}
      status={status}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
    >
      <ButtonText status={status} variant={variant} disabled={disabled}>
        {children}
      </ButtonText>
    </ButtonWrapper>
  );
};

export default Button;
