import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useReactContext } from "../../../context/Context";
import { ROUTES, URLS } from "../../../globals/constants";
import { Colors } from "../../../globals/colors";
import {
  LeftBox,
  LogosWrapper,
  RightBox,
  TopNavBarContainer,
  TopNavBarModal,
  TopNavBarWrapper,
} from "./TopNavBar.styled";
import useBreakpoints from "../../../utils/useBreakpoints";
import { cropWalletAddress } from "../../../utils/textUtils";
import Icon from "../../atoms/Icon";
import LedButton from "../../atoms/LedButton";
import { Hamburguer, Text } from "../../atoms";

function TopNavBar({ connectWallet, isWeb3Connected, refsToScroll }) {
  const navigate = useNavigate();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const { isXs, isSm } = useBreakpoints();
  const { account } = useReactContext();

  const executeScroll = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsMenuActive(false);
    }
  };

  const renderNavbarLinks = (color) => {
    const isHome = window.location.pathname === ROUTES.HOME;
    return (
      <>
        {isHome && (
          <>
            <Text
              as="h3"
              color={color}
              onClick={() => executeScroll(refsToScroll.historyRef)}
              style={{ cursor: "pointer" }}
            >
              History
            </Text>
            <Text
              as="h3"
              color={color}
              onClick={() => executeScroll(refsToScroll.teamRef)}
              style={{ cursor: "pointer" }}
            >
              Team
            </Text>
          </>
        )}
        {!isHome && (
          <Text
            as="h3"
            color={color}
            onClick={() => navigate(ROUTES.HOME)}
            style={{ cursor: "pointer" }}
          >
            Home
          </Text>
        )}
        {account && (
          <Text
            as="h3"
            color={color}
            onClick={() => navigate(ROUTES.DASHBOARD)}
            style={{ cursor: "pointer" }}
          >
            My foxes
          </Text>
        )}
        {(isSm || isXs) && renderLogos()}
      </>
    );
  };

  const renderLogos = () => {
    return (
      <LogosWrapper>
        <a href={URLS.TWITTER_PROFILE} target="_blank">
          <Icon src="/images/twitter.svg" alt="Twitter logo" />
        </a>
        <a href={URLS.DISCORD_CHANNEL} target="_blank">
          <Icon src="/images/discord.svg" alt="Discord logo" />
        </a>
        <a href={URLS.OPENSEA_COLLECTION} target="_blank">
          <Icon src="/images/opensea.svg" alt="Opensea logo" />
        </a>
      </LogosWrapper>
    );
  };

  const renderModal = () => {
    return (
      <TopNavBarModal isActive={isMenuActive}>
        {renderNavbarLinks(Colors.primary800)}
      </TopNavBarModal>
    );
  };

  const renderConnectButton = () => {
    return (
      <LedButton onClick={connectWallet} disabled={!isWeb3Connected}>
        {cropWalletAddress(account) || "Connect"}
      </LedButton>
    );
  };

  return (
    <TopNavBarWrapper>
      <TopNavBarContainer>
        <LeftBox>
          <Icon
            src="/images/1337foxlogo.png"
            alt="1337fox logo"
            onClick={() => navigate(ROUTES.HOME)}
          />
        </LeftBox>
        <RightBox>
          {!isXs && !isSm && renderNavbarLinks(Colors.tertiary1000)}
          {(isSm || isXs) && renderConnectButton()}

          {!isXs && !isSm && renderLogos()}

          {(isSm || isXs) && (
            <Hamburguer
              isActive={isMenuActive}
              setIsActive={setIsMenuActive}
              widthIcon={isSm ? 35 : 22}
              heightIcon={isSm ? 22 : 15}
              heightLine={isSm ? 3 : 2}
            />
          )}
          {!isXs && !isSm && renderConnectButton()}
        </RightBox>
      </TopNavBarContainer>
      {renderModal()}
      <ToastContainer position={"top-center"} autoClose={3500} hideProgressBar={true} />
    </TopNavBarWrapper>
  );
}

export default TopNavBar;
