const generateToken = () => {
  let isUnique = false;
  let token = "";
  let headgearValue = getRandomGear(isUnique);
  let bgValue = "";
  let bodyColorValue = "";
  let faceValue = "";
  let bodyPartsValue = "";
  let tailpiecesValue = "";

  if (Number(headgearValue) >= 38) {
    token = UNIQUE_TOKENS[headgearValue];
    isUnique = true;
    return token;
  }

  bgValue = getRandomBG();
  bodyColorValue = getRandomBodyColor();
  faceValue = getRandomGear(isUnique);
  bodyPartsValue = getRandomGear(isUnique);
  tailpiecesValue = getRandomGear(isUnique);

  token = headgearValue + bgValue + bodyColorValue + faceValue + bodyPartsValue + tailpiecesValue;
  return token;
};

const getRandomGear = (isUnique) => {
  let randomGear = "";
  let maxTokens = isUnique ? 1337 : 1324;
  const temporaryGear = getRandomNumber(1, maxTokens);

  if (temporaryGear >= 1 && temporaryGear <= 1190) {
    // common 89%
    randomGear = getRandomNumber(11, 30);
    if (randomGear <= 9) {
      randomGear = "0" + randomGear;
    }
  } else if (temporaryGear > 1190 && temporaryGear <= 1324) {
    // special 10%
    randomGear = getRandomNumber(31, 37);
  } else if (temporaryGear > 1324) {
    // unique 1%
    randomGear = getRandomNumber(38, 44);
  }
  return randomGear;
};

const getRandomBG = () => {
  let randomBG = "";
  const temporaryBG = getRandomNumber(1, 1337);

  if (temporaryBG <= 13) {
    // artifact 1%
    randomBG = "07";
  } else if (temporaryBG > 13 && temporaryBG <= 80) {
    // legendary 5%
    randomBG = "06";
  } else if (temporaryBG > 80 && temporaryBG <= 214) {
    // epic 10%
    randomBG = "05";
  } else if (temporaryBG > 214 && temporaryBG <= 428) {
    // rare 16%
    randomBG = "04";
  } else if (temporaryBG > 428 && temporaryBG <= 669) {
    // uncommon 18%
    randomBG = "03";
  } else if (temporaryBG > 669 && temporaryBG <= 963) {
    // common 20%
    randomBG = "02";
  } else if (temporaryBG > 963 && temporaryBG <= 1337) {
    // poor 27%
    randomBG = "01";
  }

  return randomBG;
};

const getRandomBodyColor = () => {
  let randomBodyColor = "0";
  const temporaryBodyColor = getRandomNumber(1, 5);
  randomBodyColor += temporaryBodyColor;
  return randomBodyColor;
};

const getRandomNumber = (min, max) => {
  if (!min || !max) {
    return;
  }
  return Math.floor(Math.random() * (max - min)) + min;
};

const UNIQUE_TOKENS = {
  38: "380701383838",
  39: "390503393939",
  40: "400605404040",
  41: "410401414141",
  42: "420101424242",
  43: "430502434343",
  44: "440701444444",
};

export { generateToken };
