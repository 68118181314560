import styled from "styled-components";
import { breakPoints } from "../../../../globals/breakpoints";
import { Colors } from "../../../../globals/colors";

const handleTextFadeIn = (isLoaded, width) => {
  let style = `
    opacity: 0;
    transform: translate(${width}px);
  `;
  if (isLoaded) {
    style = `
      opacity: 1;
      transform: translate(0);
    `;
  }
  return style;
};

const MainContainer = styled.section`
  max-width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: ${Colors.gray200};
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const MainWrapper = styled.div`
  max-width: 850px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 0 52px;

  & h1,
  h2,
  p {
    width: 100%;
    @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
      text-align: left;
    }
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    max-width: 745px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 0 32px;
  }

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const TitleBox = styled.div`
  width: 100%;
  margin-bottom: 36px;
  transition: transform 1s ease, opacity 1400ms;
  ${({ isLoaded }) => handleTextFadeIn(isLoaded, -50)}
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    margin-bottom: 13px;
  }
`;

const TextBox = styled.div`
  min-height: 503px;
  transition: transform 1s ease-out, opacity 2000ms;
  ${({ isLoaded }) => handleTextFadeIn(isLoaded, -80)}
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    min-height: 0px;
  }
`;

const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 503px;
  transition: opacity 2500ms;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    flex-direction: column-reverse;
    min-height: 0px;
  }
`;

const TokenImage = styled.img`
  max-width: 345px;
  max-height: 345px;
  width: 345px;
  height: 345px;
  border-radius: 50px;
  margin-bottom: 50px;
  transition: transform 200ms ease-out;
  &:hover {
    transform: scale(1.05);
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    max-width: 250px;
    max-height: 250px;
  }
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    max-width: 325px;
    max-height: 325px;
    margin-top: 45px;
  }
`;

export { MainContainer, MainWrapper, TitleBox, TextBox, ImageBox, TokenImage };
