const apiUrl = baseUrl();

const defaultHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  "Access-Control-Allow-Headers": "Content-Type",
};

/**
 * @name baseUrl
 * @description generate the baseURL base on the current enviroment
 */
function baseUrl() {
  return process.env.REACT_APP_API_URL;
}

/**
 * @name buildPath
 * @description create a path with params if the are params available
 */
function buildPath(options) {
  const { path, params } = options;

  if (!params) {
    return path;
  }

  const urlParams = Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");

  return `${path}?${urlParams}`;
}

/**
 * @name appendHeader
 * @description append Content-Type header if the request does not use multipart/form-data
 */
function appendHeader(options) {
  const { formData = false } = options;
  const customHeader = defaultHeaders;

  if (!formData) {
    customHeader["Content-Type"] = "application/json";
  }

  return customHeader;
}

export { apiUrl, defaultHeaders, buildPath, appendHeader };
