/* eslint-disable no-unreachable */
import { Footer } from "../../components/molecules";
import { Banner, History, Main, Team } from "./components";

function Home({ fox1337Instance, web3, historyRef, teamRef }) {
  return (
    <>
      <Banner />
      <Main fox1337Instance={fox1337Instance} web3={web3} />
      <History innerRef={historyRef} />
      <Team innerRef={teamRef} />
      <Footer />
    </>
  );
}

export default Home;
