export const ROUTES = {
  HOME: "/",
  ADMIN: "/super-admin-fox",
  DASHBOARD: "/dashboard",
};

export const URLS = {
  TWITTER_PROFILE: "https://twitter.com/_1337fox_",
  TWITTER_SHARE: ({ imageURL }) => `https://twitter.com/intent/tweet?url=${imageURL}&text=Just%20minted%20this%20cute%20@_1337fox_🦊%0a%0a`,
  INSTAGRAM_PROFILE: "https://www.instagram.com/_1337fox_/",
  MEDIUM: "https://medium.com/@1337fox",
  DISCORD_CHANNEL: "https://discord.gg/7Rg9tnvwBW",
  OPENSEA_COLLECTION: "https://opensea.io/collection/1337fox-official",
  OPENSEA_TOKEN: "https://opensea.io/assets/0x198bda8feb926b6dc6a3f9a0d04f2e074ef86315/",
  IPFS_LAYERS: "https://generator-1337fox.ew.r.appspot.com/layers",
};

export const CHAIN_IDS = {
  MAINNET: "0x1",
  RINKEBY: "0x4",
};

export const API = {
  TOKENS: "/tokens",
  METADATA: "/tokens/metadata/",
  ADDRESSES: "/addresses",
};

export const CONTRACT_ADDRESS = "0x198bda8feb926b6dc6a3f9a0d04f2e074ef86315";
