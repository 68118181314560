import styled from "styled-components";
import { breakPoints } from "../../../globals/breakpoints";
import { Colors } from "../../../globals/colors";

const FooterWrapper = styled.footer`
  width: 100%;
  padding-top: 72px;
  padding-bottom: 56px;
  background-color: ${Colors.gray200};
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 66px;
  }
`;

const FooterContainer = styled.div`
  max-width: 2160px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const IconBox = styled.div`
  width: fit-content;
  display: flex;
  gap: 35px;
  & img {
    width: 51px;
  }

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    gap: 30px;
    & img {
      width: 36px;
    }
  }
`;

export { FooterWrapper, FooterContainer, IconBox };
