import styled from "styled-components";

const IframeContainer = styled.iframe`
  width: 345px;
  height: 345px;
  border-radius: 50px;
  border: none;
`;

export { IframeContainer };
