import styled from "styled-components";
import { Colors } from "../../../globals/colors";
import { ArrowDirection } from "./ArrowButton";

const handleArrowIconDirection = (direction) => {
  let degrees = {
    [ArrowDirection.RIGHT]: "0deg",
    [ArrowDirection.BOTTOM]: "90deg",
    [ArrowDirection.LEFT]: "180deg",
    [ArrowDirection.TOP]: "270deg",
  };
  return degrees[direction];
};

const ArrowButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  rotate: ${({ direction }) => handleArrowIconDirection(direction)};
`;

export { ArrowButtonWrapper };
