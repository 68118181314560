import { useEffect, useRef, useState } from "react";
import { SCFadingText } from "./FadingText.styled";

export const textPosition = {
  LEFT: "left",
  RIGHT: "right",
};

function FadingText({ scrollBottomPosition, children, ...otherProps }) {
  const ref = useRef(null);
  const [topPosition, setTopPosition] = useState(null);
  const [status, setStatus] = useState(false);
  const distanceFromBottom = 150;

  useEffect(() => {
    setTopPosition(ref.current.getBoundingClientRect().top);
  }, [ref]);

  useEffect(() => {
    setStatus(isShown(topPosition));
  }, [scrollBottomPosition]);

  const isShown = (elementTopPosition) => {
    return scrollBottomPosition > elementTopPosition + distanceFromBottom;
  };

  return (
    <SCFadingText ref={ref} isShown={status} {...otherProps}>
      {children}
    </SCFadingText>
  );
}

export default FadingText;
