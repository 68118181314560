import { Card, Text } from "../../../../components/atoms";
import { Colors } from "../../../../globals/colors";
import useBreakpoints from "../../../../utils/useBreakpoints";
import { CardWrapper, Corner, TeamContainer, TeamWrapper } from "./Team.styled";

const TEAM_MEMBERS = [
  {
    cardBackground: "/images/card_member1_bg.png",
    cardPFP: "/images/card_member1_pfp.png",
    links: [
      "https://es.fiverr.com/sweetbrush",
      "https://www.linkedin.com/in/sweetbrush/",
      "https://www.instagram.com/sweetbrush_/",
    ],
  },
  {
    cardBackground: "/images/card_member3_bg.png",
    cardPFP: "/images/card_member3_pfp.png",
    links: ["https://twitter.com/Silinde87", "https://www.linkedin.com/in/paurodriguezmolina/"],
  },
];

export const CORNERS = {
  TOP_LEFT: "top_left",
  TOP_RIGHT: "top_right",
  BOTTOM_LEFT: "bottom_left",
  BOTTOM_RIGHT: "bottom_right",
};

const renderTeamMemberPictures = (teamMembers) => {
  return teamMembers.map(({ cardBackground, cardPFP, links }, index) => (
    <Card key={index} cardBackground={cardBackground} cardPFP={cardPFP} links={links} />
  ));
};

const renderCorners = () => {
  return (
    <>
      <Corner side={CORNERS.TOP_LEFT} />
      <Corner side={CORNERS.TOP_RIGHT} />
      <Corner side={CORNERS.BOTTOM_LEFT} />
      <Corner side={CORNERS.BOTTOM_RIGHT} />
    </>
  );
};

function Team({ innerRef }) {
  const { isXs } = useBreakpoints();
  return (
    <TeamContainer ref={innerRef}>
      <TeamWrapper>
        {!isXs && renderCorners()}
        <Text
          as="h1"
          color={Colors.gray200}
          style={{ marginBottom: "32px", width: "100%" }}
          textAlign={isXs ? "start" : "center"}
        >
          Our Team
        </Text>
        <CardWrapper>{renderTeamMemberPictures(TEAM_MEMBERS)}</CardWrapper>
      </TeamWrapper>
    </TeamContainer>
  );
}

export default Team;
