import React from "react";
import { AttributeCardContainer, AttributeLayer, ValueLayer } from "./AttributeCard.styled";

function AttributeCard({ attribute, layers }) {
  const { trait_type, value } = attribute;
  return (
    <AttributeCardContainer>
      <AttributeLayer src={layers[trait_type]} />
      <ValueLayer>{value}</ValueLayer>
    </AttributeCardContainer>
  );
}

export default AttributeCard;
