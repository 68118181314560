import { useState, useEffect } from "react";
import LedButton from "../../components/atoms/LedButton";
import { AdminContainer, ButtonsWrapper } from "./Admin.styled";
import { cropWalletAddress } from "../../utils/textUtils";
import { useReactContext } from "../../context/Context";
import getWeb3 from "../../utils/web3";
import { AdminButtons } from "./components";
import { Text } from "../../components/atoms";
import { Colors } from "../../globals/colors";

function Admin() {
  const { account, setAccount } = useReactContext();
  const [web3, setWeb3] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(async () => {
    const newWeb3 = await getWeb3();
    setWeb3(newWeb3);
  }, []);

  useEffect(async () => {
    if (web3) {
      const account = await web3.eth.getAccounts();
      setAccount(account[0]);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      };
    }
  }, [web3]);

  useEffect(() => {
    if (account) {
      setIsOwner(account === process.env.REACT_APP_OWNER_ADDRESS);
    }
  }, [account]);

  const handleAccountsChanged = () => {
    setAccount(window.ethereum.selectedAddress);
  };

  const connectWallet = async () => {
    if (web3) {
      if (!window.ethereum.selectedAddress) {
        await web3.eth.requestAccounts().catch(() => {});
      }
    }
  };

  return (
    <AdminContainer>
      <Text
        as="h1"
        color={Colors.tertiary1000}
        style={{ paddingTop: "80px", marginBottom: "60px" }}
      >
        1337Fox Admin Dashboard
      </Text>
      <ButtonsWrapper>
        <LedButton onClick={connectWallet}>{cropWalletAddress(account) || "Connect"}</LedButton>
        {isOwner && web3 && <AdminButtons web3={web3} />}
      </ButtonsWrapper>
    </AdminContainer>
  );
}

export default Admin;
