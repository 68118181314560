import { Colors } from "../../../../globals/colors";
import useBreakpoints from "../../../../utils/useBreakpoints";
import useScrollPosition from "../../../../utils/useScrollPosition";
import {
  fadeDirection,
  FadingImage,
  FadingText,
  Text,
  textPosition,
} from "../../../../components/atoms";
import {
  HistoryBox,
  HistoryContainer,
  HistoryPattern,
  HistoryWrapper,
  TitleBox,
} from "./History.styled";

function History({ innerRef }) {
  const scrollBottomPosition = useScrollPosition() + window.innerHeight;
  const { isXs } = useBreakpoints();
  return (
    <HistoryContainer ref={innerRef}>
      {!isXs && <HistoryPattern />}
      <TitleBox>
        <Text
          as="h1"
          color={Colors.primary600}
          textAlign={isXs ? "left" : "center"}
          style={{ marginTop: isXs ? "16px" : "32px" }}
        >
          History
        </Text>
      </TitleBox>
      <HistoryWrapper>
        <HistoryBox style={{ marginTop: "0px" }} isXs={isXs}>
          <FadingImage
            src="/images/history_top.png"
            width={isXs ? 350 : 500}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_LEFT}
          />
        </HistoryBox>
        <HistoryBox isXs={isXs}>
          <FadingText position={textPosition.LEFT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              "Where are we?" Fei asked. <br />
              "We are almost there," Kyubi replied with little desire for further conversation.
            </Text>
          </FadingText>
          <FadingImage
            src="/images/history1.png"
            width={isXs ? 190 : 250}
            edgeGap={isXs ? -40 : 40}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_LEFT}
          />
        </HistoryBox>
        <HistoryBox style={{ justifyContent: "flex-end" }} isXs={isXs}>
          <FadingImage
            src="/images/history2.png"
            width={isXs ? 210 : 250}
            edgeGap={isXs ? -50 : 40}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_RIGHT}
          />
          <FadingText position={textPosition.RIGHT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              The world had been dominated by shadows and desolation for too long.
              <br /> Once it was full of life, animals and plants. Pure, clean water flowed down the
              rivers to the seas. However, those times ended with the great extinction.
            </Text>
          </FadingText>
        </HistoryBox>
        <HistoryBox isXs={isXs}>
          <FadingText position={textPosition.LEFT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              Kyubi was there at that time, he was just a puppy and he knew perfectly how dangerous
              were those creatures that devastated everything.
            </Text>
          </FadingText>
          <FadingImage
            src="/images/history3.png"
            width={isXs ? 150 : 180}
            edgeGap={isXs ? 30 : 100}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_LEFT}
          />
        </HistoryBox>
        <HistoryBox style={{ justifyContent: "flex-end" }} isXs={isXs}>
          <FadingImage
            src="/images/history4.png"
            width={isXs ? 150 : 160}
            edgeGap={isXs ? 30 : 100}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_RIGHT}
          />
          <FadingText position={textPosition.RIGHT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              Fortunately, something is changing. Just a few days ago a message arrived that only
              those who lived through that disaster could understand. A message written in the
              ancient language 1337.
            </Text>
          </FadingText>
        </HistoryBox>
        <HistoryBox
          style={{
            marginTop: `${isXs ? "10px" : "50px"}`,
            marginBottom: `${isXs ? "10px" : "50px"}`,
          }}
          isXs={isXs}
        >
          <FadingText position={textPosition.LEFT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              "7h1rd full m00n 0f 7h3 y34r 22. Wh3r3 3v3ry7h1n6 3nd3d, 3v3ry7h1n6 w1ll b361n 4641n."
              it said.
            </Text>
          </FadingText>
          <FadingImage
            src="/images/history5.png"
            width={isXs ? 180 : 260}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_LEFT}
          />
        </HistoryBox>
        <HistoryBox style={{ justifyContent: "flex-end" }} isXs={isXs}>
          <FadingImage
            src="/images/history6.png"
            width={isXs ? 140 : 160}
            edgeGap={isXs ? 25 : 100}
            scrollBottomPosition={scrollBottomPosition}
            fadeDirection={fadeDirection.TO_RIGHT}
          />
          <FadingText position={textPosition.RIGHT} scrollBottomPosition={scrollBottomPosition}>
            <Text color={Colors.tertiary1000} textAlign="left" fontStyle="italic">
              Kyubi understood. The 1337 have been summoned. The survivors would return to restore
              the world that once was.
            </Text>
          </FadingText>
        </HistoryBox>
      </HistoryWrapper>
      {!isXs && <HistoryPattern isReversed={true} />}
    </HistoryContainer>
  );
}

export default History;
