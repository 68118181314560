import styled from "styled-components";
import { breakPoints } from "../../../../globals/breakpoints";
import { Colors } from "../../../../globals/colors";

const BannerContainer = styled.section`
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    ${Colors.backgrounds1000} 80%,
    ${Colors.gray200} 90%
  );
`;

const BannerImage = styled.img`
  width: 100%;
  max-width: fit-content;
  height: 512px;
  object-fit: cover;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    height: 256px;
  }
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md}px) {
    height: 320px;
  }
  @media (min-width: ${breakPoints.md}px) and (max-width: ${breakPoints.lg}px) {
    height: 384px;
  }
`;

const Cloud = styled.img`
  left: ${({ width }) => `-${width}px`};
  position: absolute;
  animation-timing-function: linear;
  animation-name: move;
  animation-iteration-count: infinite;
  ${({ duration, top, delay, width }) => `
    animation-duration: ${duration}s;
    animation-delay: ${delay}s;
    top: ${top}px;
    width: ${width}px;
  `}
  @keyframes move {
    from {
      left: ${({ width }) => `-${width}px`};
    }
    to {
      left: 100%;
    }
  }
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    ${({ duration, width, top }) => `
      animation-duration: ${duration / 2}s;
      width: ${width / 1.5}px;
      top: ${top / 1.5}px;
    `}
  }
`;

export { BannerContainer, BannerImage, Cloud };
