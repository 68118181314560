import React from "react";

function ArrowButtonSVG({ color, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={`${size}px`}
      height={`${size}px`}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <path fill={color} d="m6 15l5-5l-5-5l1-2l7 7l-7 7z" />
    </svg>
  );
}

export default ArrowButtonSVG;
