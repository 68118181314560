import styled from "styled-components";
import { breakPoints } from "../../../../globals/breakpoints";

const AdminButtonsWrapper = styled.div`
  margin: 48px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 100px;
  padding: 16px;
`;

const ButtonsWithoutInputsBox = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;

const ButtonsWithInputsBox = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    width: 250px;
  }
`;

const AdminForm = styled.form`
  display: flex;
  gap: 0 20px;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
    gap: 10px 20px;
  }
`;
const AdminInput = styled.input`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 0 16px;
`;

export {
  AdminButtonsWrapper,
  ButtonsWithoutInputsBox,
  ButtonsWithInputsBox,
  AdminForm,
  AdminInput,
};
