import styled from "styled-components";
import { breakPoints } from "../../../globals/breakpoints";

const SelectorWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 22px;
  width: fit-content;
`;

const SelectorSymbol = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export { SelectorWrapper, SelectorSymbol };
