import styled from "styled-components";
import { breakPoints } from "../../../../globals/breakpoints";
import { Colors } from "../../../../globals/colors";
import { CORNERS } from "./Team";

const getCornerStyle = (side) => {
  let style = "";
  switch (side) {
    case CORNERS.TOP_LEFT:
      style = `
        border-bottom-right-radius: 50%;
        border-top-left-radius: 50%;
        top: -5px;
        left: -5px;
      `;
      break;
    case CORNERS.TOP_RIGHT:
      style = `
        border-bottom-left-radius: 50%;
        border-top-right-radius: 50%;
        top: -5px;
        right: -5px;
      `;
      break;
    case CORNERS.BOTTOM_LEFT:
      style = `
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        bottom: -5px;
        left: -5px;
      `;
      break;
    case CORNERS.BOTTOM_RIGHT:
      style = `
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        bottom: -5px;
        right: -5px;
      `;
      break;
  }
  return style;
};

const TeamContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: ${Colors.tertiary700};

  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const TeamWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 5px solid ${Colors.tertiary1000};
  outline: 10px solid ${Colors.tertiary700};
  box-shadow: 0 0 0 15px ${Colors.tertiary1000};
  border-radius: 15px;
  padding: 40px 56px 56px 56px;
  max-width: 1022px;

  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.lg}px) {
    margin-left: 32px;
    margin-right: 32px;
    padding: 24px 32px 48px 32px;
  }
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 24px 24px 24px 24px;
  }
`;

const Corner = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  border: 5px solid ${Colors.tertiary1000};
  outline: 10px solid ${Colors.tertiary700};
  box-shadow: 0px 0px 0px 15px ${Colors.tertiary1000};
  ${({ side }) => getCornerStyle(side)}
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 30px;
  width: fit-content;
`;

export { TeamContainer, TeamWrapper, Corner, CardWrapper };
