import { useEffect, useState } from "react";
import keccak256 from "keccak256";
import { Colors } from "../../../../globals/colors";
import { useReactContext } from "../../../../context/Context";
import useBreakpoints from "../../../../utils/useBreakpoints";
import { merkleTree } from "../../../../utils/merkleTree";
import { Button, ButtonSizes, Selector, Text } from "../../../../components/atoms";
import { ImageBox, MainContainer, MainWrapper, TextBox, TitleBox, TokenImage } from "./Main.styled";
import { ButtonContainer } from "../../../../components/atoms/Button/Button.styled";
import {
  getNotRepeatedTokens,
  getNumberOfTokensToPay,
  setNotRepeatedTokens,
} from "../../../../utils/mintHelpers";
import { useToast } from "../../../../utils/hooks/useToast";

function Main({ fox1337Instance, web3 }) {
  const { isXs } = useBreakpoints();
  const [isLoaded, setIsLoaded] = useState(false);
  const [numberOfTokens, setNumberOfTokens] = useState(1);
  const { account } = useReactContext();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleClickMint = async () => {
    if (numberOfTokens <= 0 || numberOfTokens > 7) {
      return;
    }

    if (fox1337Instance) {
      const merkleProof = merkleTree.getHexProof(keccak256(account));
      const tokensBalance = await fox1337Instance.methods.balanceOf(account).call();

      const numberOfTokensToPay = getNumberOfTokensToPay(
        numberOfTokens,
        tokensBalance,
        merkleProof
      );
      const value = web3.utils.toWei((Number(numberOfTokensToPay) * 0.037).toString());

      const tokensToMint = await getNotRepeatedTokens(numberOfTokens);

      const mint = () =>
        fox1337Instance.methods
          .mint1337Fox(account, tokensToMint, numberOfTokens, merkleProof)
          .send({ from: account, value });

      useToast(mint, "Successfully minted", "Error while minting", () =>
        setNotRepeatedTokens(tokensToMint)
      );
    }
  };

  return (
    <MainContainer>
      {!isXs && (
        <TitleBox isLoaded={isLoaded}>
          <Text as="h1" color={Colors.tertiary1000} textAlign={isXs ? "start" : "center"}>
            The Collection
          </Text>
        </TitleBox>
      )}
      <MainWrapper>
        <ImageBox isLoaded={isLoaded}>
          <TokenImage
            src="https://res.cloudinary.com/dkevcmz3i/image/upload/v1646672468/1337fox/token_othef3.gif"
            alt="Foxes showcase"
          />
          <ButtonContainer>
            <Button
              onClick={handleClickMint}
              disabled={!fox1337Instance}
              size={isXs ? ButtonSizes.FULL_WIDTH : ButtonSizes.FIXED}
            >
              Mint!
            </Button>
            <Selector numberOfTokens={numberOfTokens} setNumberOfTokens={setNumberOfTokens} />
          </ButtonContainer>
        </ImageBox>
        {isXs && (
          <TitleBox isLoaded={isLoaded}>
            <Text as="h1" color={Colors.tertiary1000} textAlign={isXs ? "start" : "center"}>
              The Collection
            </Text>
          </TitleBox>
        )}
        <TextBox isLoaded={isLoaded}>
          <Text color={Colors.gray900} textAlign="start" style={{ marginBottom: "32px" }}>
            When we decided to take the plunge and start this project, we had an idea in mind. We
            wanted it to be by and for the community.
            <br />
            <br />
            1337Fox is a NFT collection where a maximum of 1337 tokens of beautiful foxes will be
            generated. All foxes traits have been drawn one by one by our illustrators.
            <br />
            Each token is unique, randomly generated at mint on the Ethereum Blockchain thanks to a
            generative script.
            <br />
            <br />
            We don't just believe that saying this is a project for the community is enough, we want
            to prove it. To do this, we will adopt 50 foxes and donate 1% of the total primary sales
            to{" "}
            <a
              href="https://gifts.worldwildlife.org/gift-center/gifts/Species-Adoptions.aspx"
              target="_blank"
              style={{ color: Colors.primary800 }}
            >
              {" "}
              World Wildlife Fund (WWF)
            </a>
          </Text>
        </TextBox>
      </MainWrapper>
    </MainContainer>
  );
}

export default Main;
