import { toast } from "react-toastify";

const useToast = async (
  promise,
  messageIfSuccess,
  messageIfError,
  callbackSuccess = () => {},
  callbackFail = () => {}
) => {
  await toast.promise(promise, {
    pending: "pending",
    success: {
      render() {
        callbackSuccess();
        return messageIfSuccess;
      },
    },
    error: {
      render() {
        callbackFail();
        return messageIfError;
      },
    },
  });
};

export { useToast };
