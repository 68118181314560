import { useRef, useEffect } from "react";
import { CardIcon, CardIconsWrapper, CardImage, CardWrapper } from "./Card.styled";

function Card({ cardBackground, cardPFP, links }) {
  const ref = useRef(null);

  const handleOnMove = (event) => {
    const card = ref.current;
    const cardRect = card.getBoundingClientRect();
    const x = (event.clientX - cardRect.left) / cardRect.width;
    const dx = -(x - 0.5);
    const dxNorm = Math.min(Math.max(dx, -0.5), 0.5);
    const y = (event.clientY - cardRect.top) / cardRect.height - 0.5;
    card.style.transform = `perspective(1000px) rotateY(${dxNorm * 45}deg) rotateX(${y * 45}deg) `;
  };

  const handleOnLeave = () => {
    ref.current.style.transform = "none";
  };

  // Disables tilt on touch screens
  const disableTiltOnTouch = () => {
    ref.current.removeEventListener("mousemove", handleOnMove);
  }

  useEffect(() => {
    ref.current.addEventListener("mousemove", handleOnMove);
    ref.current.addEventListener("mouseleave", handleOnLeave);
    ref.current.addEventListener("touchstart", disableTiltOnTouch);
    return () => {
      ref.current?.removeEventListener("mousemove", handleOnMove);
      ref.current?.removeEventListener("mouseleave", handleOnLeave);
      ref.current?.removeEventListener("touchstart", disableTiltOnTouch);
    };
  }, [ref]);

  return (
    <CardWrapper ref={ref} image={cardBackground}>
      <CardIconsWrapper links={links}>
        {links.map((link, index) => {
          return (
            <a key={index} href={link} target="_blank">
              <CardIcon />
            </a>
          );
        })}
      </CardIconsWrapper>
      <CardImage src={cardPFP} alt="Profile picture of team member" />
    </CardWrapper>
  );
}

export default Card;
