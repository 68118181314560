import styled from "styled-components";
import { breakPoints } from "../../../globals/breakpoints";
import { Colors } from "../../../globals/colors";
import { ButtonVariants, ButtonSizes } from "./Button";

// Background Color //
const handleBackgroundColor = (status, variant) => {
  const backgroundColors = {
    primary: Colors.primary800,
    secondary: Colors.secondary800,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      backgroundColors.primary = Colors.primary600;
      backgroundColors.secondary = Colors.secondary600;
      break;
  }
  return backgroundColors[status];
};

const handleHoverBackgroundColor = (status, variant) => {
  const backgroundColors = {
    primary: Colors.primary900,
    secondary: Colors.secondary900,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      backgroundColors.primary = Colors.primary700;
      backgroundColors.secondary = Colors.secondary700;
      break;
  }
  return backgroundColors[status];
};

const handleActiveBackgroundColor = (status, variant) => {
  const backgroundColors = {
    primary: Colors.primary1000,
    secondary: Colors.secondary1000,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      backgroundColors.primary = Colors.primary800;
      backgroundColors.secondary = Colors.secondary800;
      break;
  }
  return backgroundColors[status];
};

// Border Color //
const handleBorderColor = (status, variant) => {
  const borderColors = {
    primary: Colors.primary800,
    secondary: Colors.secondary800,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      break;
  }
  return borderColors[status];
};

const handleHoverBorderColor = (status, variant) => {
  const borderColors = {
    primary: Colors.primary900,
    secondary: Colors.secondary900,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      break;
  }
  return borderColors[status];
};

const handleActiveBorderColor = (status, variant) => {
  const borderColors = {
    primary: Colors.primary1000,
    secondary: Colors.secondary1000,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      break;
  }
  return borderColors[status];
};

// Text Color //
const handleTextColor = (status, variant) => {
  const textColors = {
    primary: Colors.gray200,
    secondary: Colors.gray200,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      textColors.primary = Colors.primary800;
      textColors.secondary = Colors.secondary800;
      break;
  }
  return textColors[status];
};

const handleHoverTextColor = (status, variant) => {
  const textColors = {
    primary: Colors.gray200,
    secondary: Colors.gray200,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      textColors.primary = Colors.primary900;
      textColors.secondary = Colors.secondary900;
      break;
  }
  return textColors[status];
};

const handleActiveTextColor = (status, variant) => {
  const textColors = {
    primary: Colors.gray200,
    secondary: Colors.gray200,
  };
  switch (variant) {
    case ButtonVariants.FILLED:
      break;
    case ButtonVariants.OUTLINED:
      textColors.primary = Colors.primary1000;
      textColors.secondary = Colors.secondary1000;
      break;
  }
  return textColors[status];
};

// Button width //
const handleMinWidth = (size) => {
  let width = "260px";
  if (size === ButtonSizes.FULL_WIDTH) width = "100%";
  return width;
};

const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.button`
  cursor: pointer;
  border-radius: 21px;
  height: 56px;
  transition: background-color 200ms, border 200ms;
  ${({ variant, status, size }) => `
    background-color: ${handleBackgroundColor(status, variant)};
    border: 2px solid ${handleBorderColor(status, variant)};
    width: ${handleMinWidth(size)};
    &:hover {
      background-color: ${handleHoverBackgroundColor(status, variant)};
      border-color: ${handleHoverBorderColor(status, variant)};
      & span {
        color: ${handleHoverTextColor(status, variant)};
      }
    }
    &:active {
      background-color: ${handleActiveBackgroundColor(status, variant)};
      border-color: ${handleActiveBorderColor(status, variant)};
      & span {
        color: ${handleActiveTextColor(status, variant)};
      }
    }
  `}
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      background-color: ${Colors.gray700};
      border-color: ${Colors.gray700};
      & span {
        color: ${Colors.gray800};
      }
      &:hover, &:active {
        background-color: ${Colors.gray700};
        border-color: ${Colors.gray700};
        & span {
          color: ${Colors.gray800};
        }
      }
  `}
`;
const ButtonText = styled.span`
  font-size: 30px;
  font-weight: 800;
  line-height: 38px;
  width: 100%;
  display: inline-block;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  transition: color 200ms;
  ${({ variant, status }) => `
    color: ${handleTextColor(status, variant)};
  `};
  ${({ disabled }) =>
    disabled &&
    `
      color: ${Colors.gray800};
      &:hover, &:active {
        color: ${Colors.gray800};
      }
  `}
`;

export { ButtonContainer, ButtonWrapper, ButtonText };
