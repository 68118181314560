export const Colors = {
  gray1000: "#000000",
  gray900: "#2F2F2F",
  gray800: "#919191",
  gray700: "#D3D3D3",
  gray600: "#EAEAEA",
  gray200: "#FFFFFF",

  primary1000: "#BE457B",
  primary900: "#CC6794",
  primary800: "#DE86AA",
  primary700: "#F0C4D7",
  primary600: "#FFDFEB",
  primary200: "#FAEEF3",

  secondary1000: "#4D897D",
  secondary900: "#5C9F92",
  secondary800: "#83B6AC",
  secondary700: "#B1D1CB",
  secondary600: "#D6E7E4",

  tertiary1000: "#6088BF",
  tertiary800: "#80A0CC",
  tertiary700: "#AABFDD",

  danger1000: "#A53E3E",
  danger900: "#D44F4F",

  backgrounds1000: "#AFF6F6",
  
  openseaBlue: "#2081E2",
  twitterBlue: "#1DA1F2"
};
