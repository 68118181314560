import styled from "styled-components";
import { Colors } from "../../../globals/colors";

const HamburguerWrapper = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
  transform: translateY(${({ heightLine }) => `${-heightLine / 2}px`});

  ${({ widthIcon, heightIcon }) => `
    width: ${widthIcon}px;
    min-width: ${widthIcon}px;
    height: ${heightIcon}px;
  `}

  &:focus {
    outline: none;
  }

  .line {
    display: block;
    background: ${Colors.primary800};
    position: absolute;
    left: 0;
    transition: all 400ms;

    ${({ widthIcon, heightLine }) => `
        width: ${widthIcon}px;
        height: ${heightLine}px;
        border-radius: ${heightLine / 2}px; 
    `};

    &.line-1 {
      top: 0%;
    }
    &.line-2 {
      top: 50%;
    }
    &.line-3 {
      top: 100%;
    }
  }

  ${({ isActive, heightIcon }) =>
    isActive &&
    `
      .line-1 {
        transform: translateY(${heightIcon / 2}px) translateX(0px) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY(${(heightIcon / 2) * -1}px) translateX(0px) rotate(-45deg);
      }      
  `}
`;

export { HamburguerWrapper };
