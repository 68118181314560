import { API } from "../globals/constants";
import http from "./http-services";

const TokenService = () => {
  const getTokensList = () => {
    const options = {
      path: API.TOKENS,
    };
    return http
      .get(options)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };

  const setTokens = (data) => {
    const options = {
      path: API.TOKENS,
      data,
    };
    return http
      .post(options)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };

  const getMetadataByTokenId = (tokenId) => {
    const options = {
      path: API.METADATA + tokenId,
    };
    return http
      .get(options)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };

  return {
    getTokensList,
    setTokens,
    getMetadataByTokenId,
  };
};

export default TokenService();
