/* eslint-disable no-console */
import MerkleTree from "merkletreejs";
import keccak256 from "keccak256";
import { whitelistAddresses } from "./../static/addresses.json";

let merkleTree;
let leafNodes = whitelistAddresses.map((address) => Buffer.from(address, "hex"));

merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

// This is how we get the merkleRoot for the contract
const rootHash = merkleTree.getHexRoot();

const claimingAddress = leafNodes[0];

// This is how we check if an address is wl. keccak256(address) as a parameter.
// returns [addresses] if true or returns [] if false;
const hexProof = merkleTree.getHexProof(claimingAddress);

export { merkleTree };
