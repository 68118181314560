import { BannerContainer, BannerImage, Cloud } from "./Banner.styled";

function Banner() {
  return (
    <BannerContainer>
      <BannerImage src="/images/banner.png" alt="Landscape with kitsune in the middle" />
      <Cloud src="/images/cloud1.png" alt="cloud" duration={"105"} top={70} delay={1} width={80} />
      <Cloud src="/images/cloud1.png" alt="cloud" duration={"75"} top={85} delay={40} width={90} />
      <Cloud src="/images/cloud2.png" alt="cloud" duration={"55"} top={95} delay={15} width={100} />
      <Cloud src="/images/cloud2.png" alt="cloud" duration={"35"} top={120} delay={0} width={150} />
    </BannerContainer>
  );
}

export default Banner;
