import { useEffect, useRef, useState } from "react";
import useBreakpoints from "../../../utils/useBreakpoints";
import { SCFadingImage } from "./FadingImage.styled";

export const fadeDirection = {
  TO_LEFT: "left",
  TO_RIGHT: "right",
};

function FadingImage({ scrollBottomPosition, ...otherProps }) {
  const ref = useRef(null);
  const { isXs } = useBreakpoints();

  const [topPosition, setTopPosition] = useState(null);
  const [status, setStatus] = useState(false);

  const distanceFromBottom = isXs ? 100 : 170;

  useEffect(() => {
    setTopPosition(ref.current.getBoundingClientRect().top);
  }, [ref]);

  useEffect(() => {
    setStatus(isShown(topPosition));
  }, [scrollBottomPosition]);

  const isShown = (elementTopPosition) => {
    return scrollBottomPosition > elementTopPosition + distanceFromBottom;
  };

  return <SCFadingImage ref={ref} isShown={status} {...otherProps}></SCFadingImage>;
}

export default FadingImage;
