import { createGlobalStyle } from "styled-components";
import { breakPoints } from "./globals/breakpoints";
import "react-toastify/dist/ReactToastify.css";
import { Colors } from "./globals/colors";

const GlobalStyles = createGlobalStyle`
:root {
  --toastify-color-light: ${Colors.primary200};
  --toastify-text-color-light: ${Colors.primary800};
  --toastify-icon-color-info: ${Colors.primary800};
  --toastify-font-family: OpenSans;
}
* {
  box-sizing: border-box;
}
html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    .Toastify__close-button {
      color: ${Colors.primary800};
    }
}
html, body {
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
  background-color: ${Colors.gray200};
}
a {
  text-decoration: none;
}
h1 {
  font-family: LuckiestGuy;
  margin: 0;
}
h2,h3,h4,h5,h6,span,p {
    font-family: OpenSans;
    margin: 0;
}
h1 {
  font-weight: 400;
  font-size: 48px;
  line-height: 49px;
  letter-spacing: 0em;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    font-size: 32px;
    line-height: 44px;
  }
}
h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0em;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
  }
}
h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0em;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    font-size: 20px;
    line-height: 27px;
  }
}
span,p {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0em;
  @media (min-width: ${breakPoints.xs}px) and (max-width: ${breakPoints.sm}px) {
    font-size: 16px;
    line-height: 29px;
  }
}

`;

export default GlobalStyles;
