const cropWalletAddress = (address) => {
  if (!address) {
    return "";
  }
  if (address.slice(0, 2) !== "0x") {
    return address;
  }
  return address.slice(0, 4) + "..." + address.slice(-3);
};

export { cropWalletAddress };
