import styled from "styled-components";
import { Text } from "../../../../components/atoms";
import { Colors } from "../../../../globals/colors";

const AttributeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AttributeLayer = styled.img`
  height: 142px;
  width: 142px;
  border: 1px solid ${Colors.primary700};
  border-radius: 21px;
`;

const ValueLayer = styled(Text)``;

export { AttributeCardContainer, AttributeLayer, ValueLayer };
